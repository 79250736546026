import React, { useEffect  } from "react";
//import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as HomePrimary } from "assets/images/icons/home_primary.svg";
import { ReactComponent as DriverPrimary } from "assets/images/icons//driver-avatar.svg";
import { ReactComponent as OrdersPrimary } from "assets/images/icons/orders_primary.svg";
import { ReactComponent as OTCOrdersPrimary } from "assets/images/icons/otc-order.svg";
import { ReactComponent as MessagesPrimary } from "assets/images/icons/messages_primary.svg";
import { ReactComponent as PatientsPrimary } from "assets/images/icons/patients_primary.svg";
import { ReactComponent as WorkersPrimary } from "assets/images/icons/workers_primary.svg";
import { ReactComponent as ProfilePrimary } from "assets/images/icons/profile_primary.svg";
import { ReactComponent as ApiKeyAndServicesPrimary } from "assets/images/icons/api_key_services.svg";
import { ReactComponent as DoctorPrimary } from "assets/images/icons/doctor_primary.svg";
import { ReactComponent as PharmacyPrimary } from "assets/images/icons/pharmacy_primary.svg";
import { ReactComponent as PaymentPrimary } from "assets/images/icons/payment_primary.svg";
import { ReactComponent as RemoteWorkerPrimary } from "assets/images/icons/remote-working.svg";
import { ReactComponent as AddWhite } from "assets/images/icons/add_white.svg";
import { ReactComponent as Invoice } from "assets/images/icons/invoice.svg";
import { ORDERS_ROUTE, ROUTE } from "utils/constants";
import { NavLink, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { useWorker } from "hooks/useWorker";
// import { ReactComponent as MenuSVG } from "assets/images/icons/menu.svg";
import { logCustom } from "firebaseInitial";
import { useDefaultStore } from "hooks/useDefaultStore";
import workersApi from "apis/workers";
import { useDispatch } from "react-redux";
import { save } from "redux/actions/defaultStore";

const routes = Object.values(ROUTE);

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#FFFFFF",
  },
  "&::-webkit-scrollbar": {
    width: 0,
    backgroundColor: "#FFFFFF",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const BoxSidebar = styled(Box)(({ theme, isOpen }) => ({
  display: "flex",
  padding: !isOpen ? "0 8px" : "0 32px",
  justifyContent: "center",
  flexDirection: "column",
}));

const BoxLogo = styled(Box)(({ theme }) => ({
  "& svg": {
    width: "100%",
  },
  borderBottom: "1px solid #D6DEED",
}));

const BoxCreateOrderCTA = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(5)} 0`,
  borderBottom: "1px solid #D6DEED",
}));

const CreateOrderButton = styled(Button)(({ theme, isOpen }) => ({
  color: theme.palette.white.main,
  width: "100%",
  borderRadius: theme.spacing(2),
  height: 50,
  fontWeight: 600,
  textTransform: "inherit",
  fontSize: theme.spacing(2),
  minWidth: !isOpen && "auto",
}));

const AddWhiteIcon = styled(AddWhite)(({ theme, isOpen }) => ({
  marginRight: isOpen && theme.spacing(1),
}));

const BoxNavigation = styled(Box)(({ theme }) => ({}));

const BoxCopyRight = styled(Box)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey.main,
  fontWeight: 500,
  margin: "40px 30px",
}));

const NavigationList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  paddingInlineStart: 0,
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginTop: theme.spacing(4),
  "& a": {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
  },
  "& .active": {
    "& li": {
      backgroundColor: theme.palette.yellow.main,
    },
  },
}));

const NavigationItem = styled("li")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  margin: `${theme.spacing(1)} 0`,
  borderRadius: theme.spacing(2),
  cursor: "pointer",
  border: `1px solid ${theme.palette.white.main}`,
  "&:hover": {
    border: `1px solid ${theme.palette.yellow.main}`,
  },
}));

const NavigationIcon = styled(Box)(({ theme, isOpen }) => ({
  marginRight: isOpen && 12,
  width: 30,
  textAlign: "center",
  display: "flex",
}));

const NavigationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  lineHeight: "18px",
  fontWeight: 600,
}));

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

const Sidebar = ({ isOpen }) => {
  const navigate = useNavigate();
  const renderIcon = (icon) => {
    switch (icon) {
      case ROUTE.ROOT.ICON:
        return <HomePrimary />;
      case ROUTE.DRIVER.ICON:
        return <DriverPrimary />;
      case ROUTE.ORDERS.ICON:
        return <OrdersPrimary />;
        case ROUTE.OTC_ORDERS.ICON:
        return <OTCOrdersPrimary />;
      case ROUTE.VIRTUAL_STAFF.ICON:
        return <RemoteWorkerPrimary />;
      case ROUTE.MESSAGES.ICON:
        return <MessagesPrimary />;
      case ROUTE.PATIENTS.ICON:
        return <PatientsPrimary />;
      case ROUTE.WORKERS.ICON:
        return <WorkersPrimary />;
      case ROUTE.PROFILE.ICON:
        return <ProfilePrimary />;
      case ROUTE.API_KEY_AND_SERVICES.ICON:
        return <ApiKeyAndServicesPrimary />;
      case ROUTE.DOCTORS.ICON:
        return <DoctorPrimary />;
      case ROUTE.INVOICE.ICON:
        return <Invoice />;
        case ROUTE.PAYMENT.ICON:
        return <PaymentPrimary />;
      case ROUTE.PHARMACY_FAX.ICON:
        return <PharmacyPrimary />;


      default:
        return <HomePrimary />;
    }
  };
  const worker = useWorker();
  const defaultStore = useDefaultStore();
  const { roles = [], company } = worker || {};
  //const {  is_enable_doctor } = company || false;
  //console.log("is_enable_doctor");
  //console.log(is_enable_doctor);
  //const { company = [] } = worker || {};
  console.log(roles);
  const isCompanyStaff = roles.includes("company_staff");
  const isCompanySupporter = roles.includes("company_supporter");
  const isCompanyManager = roles.includes("company_manager");
  const isStoreManager = roles.includes("store_manager");
  const location = useLocation();
  const { pathname } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const onLoginAs = async (data) => {
    try {
      const res = await workersApi.logInAs(data);
      localStorage.setItem(
        "worker",
        JSON.stringify({ ...res, isLogged: true })
      );

      const { current_store, roles = [] } = res || {};

      if (Array.isArray(roles) && roles.includes("company_manager")) {
        dispatch(
          save({
            name: "All",
            id: "all",
          })
        );
        localStorage.setItem(
          "defaultStore",
          JSON.stringify({
            name: "All",
            id: "all",
          })
        );
      }

      if (Array.isArray(roles) && roles.includes("company_supporter")) {
        dispatch(
          save({
            name: "All",
            id: "all",
          })
        );
        localStorage.setItem(
          "defaultStore",
          JSON.stringify({
            name: "All",
            id: "all",
          })
        );
      }

      if (
        Array.isArray(roles) &&
        !roles.includes("company_manager") &&
        current_store &&
        current_store.id
      ) {
        dispatch(save(current_store));
        localStorage.setItem("defaultStore", JSON.stringify(current_store));
      }
      navigate("/", { replace: false });

    } catch (error) {
      console.log(error);
      navigate("/sign-in", { replace: false });
    }
  }
  useEffect(() => {
    if (pathname === "/login_as") {
      const token = searchParams.get("admin_login_request_token");
      if (token) {
        const res = onLoginAs({ "request_token": token });

      }

      //call api to get real token
      return;
    }
  },);

  if (
    worker.company_type === "self_service_account" &&
    (pathname === "/invoice" ||
      pathname === "/doctors" ||
      pathname === "/pharmacy-fax")
  ) {
    navigate("/404", {
      replace: false,
    });
  }



  const onGoToCreateOrderPage = () => {
    navigate(`${ROUTE.ORDERS.URL}${ORDERS_ROUTE.CREATE.URL}`, {
      replace: false,
    });
  };

  const onClickLink = (TRACK) => {
   logCustom(TRACK, defaultStore, worker);
  };

  const canAccess = (route) => {
    if (
      (worker.company_type === "self_service_account" &&
      (route.URL === "/invoice" ||
        route.URL === "/doctors" ||
          route.URL === "/pharmacy-fax")) //||
        //route.URL === "/api-key" 
    ) {
      return false;
    }
    if (worker.company_type === "standard" && route.URL === "/payment") {
        return false;
    }
    console.log("is_enable_doctor");
    console.log(worker.company?.is_enable_doctor);
    if (worker.company?.is_enable_doctor == false && route.URL === "/doctors") {
        return false;
    }

    return true;
  };

  return (
    <Drawer variant="permanent" open={isOpen}>
      <BoxSidebar isOpen={isOpen}>
        <BoxLogo>
          <Logo />
        </BoxLogo>

        {(isCompanyManager || isCompanyStaff || isStoreManager) && (
          <BoxCreateOrderCTA>
            <CreateOrderButton
              variant="contained"
              color="blue"
              onClick={onGoToCreateOrderPage}
            >
              <AddWhiteIcon isOpen={isOpen} /> {isOpen && "Create Order"}
            </CreateOrderButton>
          </BoxCreateOrderCTA>
        )}

        <BoxNavigation>
          <NavigationList>
            {routes.map((route) => {
              const { NAME: name, ICON: icon, URL: url, HIDE: hide, TRACK } = route;
              if (isCompanyStaff && icon === "workers") return;
              if (isCompanyStaff && icon === "api-key") return;

              if (isCompanySupporter && icon === "workers") return;
              if (isCompanySupporter && icon === "api-key") return;

              return (
                !hide &&
                canAccess(route) && (
                  <NavLink to={url} exact key={name} onClick={isActive => onClickLink(TRACK)}>
                    <NavigationItem>
                      <NavigationIcon isOpen={isOpen}>
                        {renderIcon(icon)}
                      </NavigationIcon>
                      {isOpen && <NavigationText>{name}</NavigationText>}
                    </NavigationItem>
                  </NavLink>
                )
              );
            })}
          </NavigationList>
        </BoxNavigation>

        {isOpen && (
          <BoxCopyRight>
            {`© ${new Date().getFullYear()} RXDeliveredNow.`}
          </BoxCopyRight>
        )}
      </BoxSidebar>
    </Drawer>
  );
};

export default React.memo(Sidebar);
