import React, { useEffect, useState } from "react";
import DriverMap from "./Map/DriverMap";
import { Box, styled, Tab, Tabs, Typography } from "@mui/material";
import { useDefaultStore } from "hooks/useDefaultStore";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "utils/constants";
import SelectStoreModal from "../../components/Modal/SelectStore";
import PropTypes from 'prop-types';
import AnalyzeData from "./Analyze/AnalyzeData";

const BoxPage = styled(Box)(() => ({}));

const DriverHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.blue.main,
    fontSize: 24,
    fontWeight: 500,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Driver = () => {
    const [value, setValue] = React.useState(0);
    const [isOpenSelectStoreModal, setIsOpenSelectStoreModal] = useState(false);
    const storeData = useDefaultStore();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_API_KEY
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (storeData.id === "all") {
            setIsOpenSelectStoreModal(true);
        }
        localStorage.removeItem("analyzeDataDate");
        localStorage.removeItem("selectedDate");
    }, [])

    const onCloseSelectStoreModal = () => {
        setIsOpenSelectStoreModal(false);
    };

    return (
        isLoaded && <BoxPage>
            {isOpenSelectStoreModal && (
                <SelectStoreModal
                    isOpen={isOpenSelectStoreModal}
                    onClose={onCloseSelectStoreModal}
                />
            )}
            <DriverHeader>Drivers</DriverHeader>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Map" {...a11yProps(0)} />
                    <Tab label="Analyze" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <DriverMap
                    storeData={storeData}
                    isLoaded={isLoaded}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <AnalyzeData
                    storeData={storeData}
                />
            </CustomTabPanel>
        </BoxPage>

    );
}

export default React.memo(Driver);