import { useEffect, useState } from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const { styled, Box } = require("@mui/material");

const DataBox = styled(Box)(({ backgroundColor }) => ({
    width: "calc((100%) / 3)",
    borderRadius: "16px",
    padding: "15px",
    margin: "5px",
    backgroundColor: backgroundColor,
}));

const DataTotalInfo = ({ label, data, infoName, backgroundColor, compareData }) => {
    const [status, setStatus] = useState();

    useEffect(() => {
        if (compareData >= 0) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }, [compareData]);

    return (
        <DataBox backgroundColor={backgroundColor}>
            <p style={{ margin: '0px' }}>{label}</p>
            <h1 style={{ margin: '0px', color: '#041967' }}>{data} {infoName}</h1>
            {data !== 0 && (
                <div>
                    {status ? (
                    <div style={{ display: "flex", color: "green" }}>
                        <ArrowUpwardIcon />
                        <p style={{ margin: '0px' }}>{compareData}% vs last 7 days</p>
                    </div>
                ) : (
                    <div style={{ display: "flex", color: "red" }}>
                        <ArrowDownwardIcon />
                        <p style={{ margin: '0px' }}>{compareData}% vs last 7 days</p>
                    </div>
                )}
                </div>
            )}
        </DataBox>
    )
}

export default DataTotalInfo