import React, { useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  TextField,
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select"
import { getAllDrivers, getAllTasks } from "apis/driverClient";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { convertTZ, formatDate } from "utils/utils";
import { GoogleMap } from "@react-google-maps/api";

import DriverList from "./DriverList";
import SelectedAddress from "./Components/SelectedAddress";
import SelectedDriverPath from "./Components/SelectedDriverPath";
import DriverPosition from "./Components/DriverPosition";

const RecipientList = styled(Box)(() => ({
  width: "33%",
  maxWidth: "33%",
  height: "70vh",
  overflowY: "auto",
  backgroundColor: "#E6F9FD",
}))

const NoDriverTitle = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "center",
  marginTop: "30vh",
}));

const BoxSelector = styled(Box)(() => ({
  width: "full",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "20px"
}));

const TypeSelector = styled(Select)(({ theme }) => ({
  width: "25vw",
  borderRadius: theme.spacing(2),
}));

const MapBox = styled(Box)(() => ({
  display: "flex",
  padding: "12px 0px",
}));

const DriverMap = ({ storeData, isLoaded }) => {
  const usDate = convertTZ(new Date(), "America/New_York");
  const defaultFilteredDate = formatDate(usDate);

  const [drivers, setDrivers] = useState([{}]);
  const [tasksInfo, setTasksInfo] = useState([]);
  const [workerId, setWorkerId] = useState("");
  const [workerIdForComponent, setWorkerIdForComponent] = useState("");
  const [date, setDate] = useState(usDate);
  const [filteredDate, setFilteredDate] = useState(defaultFilteredDate);
  const [npiKey, setNpiKey] = useState("");

  const [selectedTaskFromList, setSelectedTaskFromList] = useState();
  const [selectedDriverFromList, setSelectedDriverFromList] = useState();

  const [taskIndex, setTaskIndex] = useState();

  const [selectedDriverTask, setSelectedDriverTask] = useState();
  const [driverPosition, setDriverPosition] = useState([]);

  //Used to get the selected date if existed
  function fetchDate() {
    if (localStorage.getItem("selectedDate")) {
      const localStorageDate = localStorage.getItem("selectedDate");
      const lsDate = new Date(localStorageDate);
      setDate(lsDate);
      const selectedDate = formatDate(lsDate);
      setFilteredDate(selectedDate);
    }
  }

  useEffect(() => {
    fetchDate();
  }, []);

  //Used to set the selected date
  useEffect(() => {
    localStorage.setItem("selectedDate", date);
  }, [date]);

  // const [isOpenSelectStoreModal, setIsOpenSelectStoreModal] = useState(false);

  // useEffect(() => {
  //   if (typeof window !== "undefined" && !scriptLoaded.current) {
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
  //     document.body.appendChild(script);
  //     scriptLoaded.current = true;
  //   }
  // }, []);

  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: GOOGLE_MAP_API_KEY, // Add your API Key here
  //   libraries: ['places'] // Add other libraries if needed
  // });

  // useEffect(() => {
  //   const newPairs = [];
  //   if (tasksInfo !== null) {
  //     for (let i = 0; i < tasksInfo.length - 1; i++) {
  //       newPairs.push({
  //         current: {
  //           lat: parseFloat(tasksInfo[i].arrival_lat),
  //           lng: parseFloat(tasksInfo[i].arrival_long), // Changed 'long' to 'lng'
  //         },
  //         next: {
  //           lat: parseFloat(tasksInfo[i + 1].arrival_lat),
  //           lng: parseFloat(tasksInfo[i + 1].arrival_long), // Changed 'long' to 'lng'
  //         },
  //       });
  //     }
  //   }
  //   setPairs(newPairs);
  // }, [tasksInfo]);

  const [map, setMap] = useState(null)

  // const storeData = useDefaultStore();

  // useEffect(() => {
  //   if (storeData.id === "all") {
  //     setIsOpenSelectStoreModal(true);
  //   }
  // }, [])

  // const onCloseSelectStoreModal = () => {
  //   setIsOpenSelectStoreModal(false);
  // };

  useEffect(() => {
    setNpiKey(storeData.npi);
  }, [storeData])

  const driverKey = {
    //npi: "1780836676", //test npi key
    npi: `${npiKey}`,
    start: `${filteredDate} 00:00:00`,
    from: `${filteredDate} 23:59:59`
  };

  const routeKey = {
    worker_id: `${workerId}`,
    //npi: "1780836676", //test npi key
    npi: `${npiKey}`,
    start: `${filteredDate} 00:00:00`,
    from: `${filteredDate} 23:59:59`
  }

  async function fetchDriversData() {
    if (storeData.id !== 'all') {
      if (npiKey && filteredDate) {
        const response = await getAllDrivers(driverKey.npi, driverKey.start, driverKey.from);
        setDrivers(response);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchDriversData();
    }, 500);
  }, [driverKey.from, driverKey.npi, driverKey.start]);

  // useEffect(() => {
  //   async function fetchDateOnFirst() {
  //     const selectedDate = await formatDate(date);
  //     setFilteredDate(selectedDate);
  //   }
  //   fetchDateOnFirst();
  // }, [])

  useEffect(() => {
    setTimeout(() => {
      filterTasksData();
    }, 500)
  }, [routeKey.from, routeKey.npi, routeKey.start, routeKey.worker_id])
  
  function fetchMapBounds() {
    if (map) {
      if (!workerId || !tasksInfo) {
        return;
      }
      // eslint-disable-next-line no-undef
      const bounds = new google.maps.LatLngBounds();

      if (tasksInfo && tasksInfo.map) {
        tasksInfo.map((task) => {
          // eslint-disable-next-line no-undef
          bounds.extend(new google.maps.LatLng(parseFloat(task.arrival_lat), parseFloat(task.arrival_long)));
        });
      }

      map.fitBounds(bounds);
    }
  }

  // useEffect(() => {
  //   async function fetchTasksData() {
  //     if (storeData.id !== 'all') {
  //       const response = await getAllTasks(routeKey.worker_id, routeKey.npi, routeKey.start, routeKey.from);
  //       setTasksInfo(response);
  //     }
  //   }
  //   fetchTasksData();
  //   filterDriverById();
  //   const newPairs = [];
  //   if (tasksInfo !== null) {
  //     for (let i = 0; i < tasksInfo.length - 1; i++) {
  //       const taskDate = new Date(tasksInfo[i].onfleet_time);
  //       if (!(tasksInfo[i].status === 1
  //         && taskDate.getFullYear() === new Date().getFullYear()
  //         && taskDate.getMonth() === new Date().getMonth()
  //         && taskDate.getDate() === new Date().getDate())) {
  //         newPairs.push(tasksInfo);
  //       }
  //     }
  //     setTasksInfo(newPairs);
  //   }

  // }, [routeKey.from, routeKey.npi, routeKey.start, routeKey.worker_id])

  async function fetchTasksData() {
    if (storeData.id !== 'all') {
      if (npiKey && filteredDate && workerId !== "") {
        const response = await getAllTasks(
          routeKey.worker_id, routeKey.npi, routeKey.start, routeKey.from);
        return response;
      }
      return null;
    }
  }

  //Used to filter completed tasks and set the current position of the driver
  //if the selected date is today
  async function filterTasksData() {
    const fetchedData = await fetchTasksData();
    if (fetchedData) {
      if (fetchedData.metadata.location) {
        setDriverPosition(fetchedData.metadata.location);
      } else {
        setDriverPosition([]);
      }
      const tasksData = fetchedData.tasks;
      let newPairs = [];
      const date = new Date();
      const usDate = convertTZ(date, "America/New_York");
      if (tasksData !== null) {
        for (let i = 0; i < tasksData.length; i++) {
          const taskDate = new Date(tasksData[i].onfleet_time);
          if (tasksData[i].status === 1
            && taskDate.getFullYear() === usDate.getFullYear()
            && taskDate.getMonth() === usDate.getMonth()
            && taskDate.getDate() === usDate.getDate()) {
          } else {
            newPairs.push(tasksData[i]);
          }
        }
        setTasksInfo(newPairs);
      }
    }
  }

  //Used to auto zoom the map to fit all marker
  useEffect(() => {
    if (isLoaded) {
      fetchMapBounds();
    }
  }, [isLoaded, tasksInfo]);

  const handleMapLoad = (map) => {
    setMap(map);
    fetchMapBounds();
  };

  function filterDriverById(selectedDriver) {
    if (selectedDriver !== "") {
      const result = drivers.filter(driver => driver.onfleet_worker_id === selectedDriver);
      setSelectedDriverTask(result[0]);
    }
  }

  const handleSelectDriverChange = (e) => {
    const selectedDriver = e.target.value;
    setWorkerId(selectedDriver);
    setWorkerIdForComponent(selectedDriver);
    setSelectedDriverTask(null);
    setSelectedTaskFromList(null);
    if (selectedDriver === "") {
      setTasksInfo(null);
      setDriverPosition([]);
    }
    filterDriverById(selectedDriver);
  }

  const handleSelectDateChange = (e) => {
    const selectedDate = e ? formatDate(e) : null;
    //set current date to the selected date
    setDate(e);
    setFilteredDate(selectedDate);
    //Set the others state to the default
    setTasksInfo(null);
    setWorkerId("");
    setWorkerIdForComponent("");
    setSelectedDriverTask(null);
    setSelectedTaskFromList(null);
    setDrivers(null);
    setDriverPosition([]);
  }

  const handleTaskListData = (task) => {
    setSelectedTaskFromList(task);
  }

  const handleDriverListData = (driver) => {
    setWorkerId(driver.onfleet_worker_id);
    setSelectedDriverFromList(driver);
    setSelectedTaskFromList(null);
  }

  const handleTaskIndex = (index) => {
    setTaskIndex(index);
  }

  const center = {
    lat: 34.201503,
    lng: -118.5277254,
  }

  return (
    // isLoaded && <BoxPage>
    //   {isOpenSelectStoreModal && (
    //     <SelectStoreModal
    //       isOpen={isOpenSelectStoreModal}
    //       onClose={onCloseSelectStoreModal}
    //     />
    //   )}
    <>
      {/* <DriverHeader>Drivers</DriverHeader> */}
      <section>
        <BoxSelector>

          <div>
            <InputLabel>Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date}
                onChange={(e) => handleSelectDateChange(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      pattern: '\\d{4} \\d{2} \\d{2}', // Adding pattern for validation
                      title: 'Please enter a date in the format YYYY MM DD', // Adding title for input field
                    }}
                    sx={{
                      width: "170px",
                      border: "1px solid",
                      borderRadius: "16px",
                      borderColor: "#D6DEED",
                      padding: "11px",
                    }}
                  />
                )}
                inputFormat="yyyy/MM/dd"
              />
            </LocalizationProvider>
          </div>

          <div>
            <InputLabel>Drivers</InputLabel>
            <TypeSelector
              id="demo-simple-select-outlined"
              value={workerIdForComponent}
              displayEmpty
              label="Driver"
              onChange={(e) => handleSelectDriverChange(e)}
              defaultValue=""
            >
              <MenuItem key={'-1'} value="">Drivers</MenuItem>
              {drivers && drivers.map && drivers.map((driver) => (
                <MenuItem key={driver.id} value={driver.onfleet_worker_id}>{driver.name}</MenuItem>
              ))}
            </TypeSelector>
          </div>
        </BoxSelector>
      </section>

      <section>
        <MapBox>
          <GoogleMap
            onLoad={handleMapLoad}
            mapContainerStyle={{ width: '67%', height: '70vh' }}
            center={center}
            zoom={12}
            onClick={() => fetchMapBounds()}
          // onBoundsChanged={() => {
          //   onBoundsChanged();
          // }}
          >
            {selectedTaskFromList && workerId === "" ? (
              <>
              </>
            ) : (
              // load driver's path when selected from list or drop down
              <SelectedDriverPath
                driverPosition={driverPosition}
                tasksInfo={tasksInfo}
              />
            )}

            {/* Used to load all driver's position on default */}
            {drivers && driverPosition.length === 0 ? (
              <>
                {drivers && drivers.map && drivers.map((driver) => (
                  <DriverPosition
                    driver={driver}
                    filteredDate={filteredDate}
                    npiKey={npiKey}
                  />
                ))}
              </>
            ) : (
              <>
              </>
            )}

            {/* {tasksInfo && tasksInfo.map && tasksInfo.map((task, index) => (
              <DirectionsFlightPath key={task.id}
                task={task}
                taskIndex={index}
              />
            ))} */}

            {/* {pairs && pairs.map && pairs.map((position, index) => (
              <Polyline
                key={index}
                path={
                  [
                    { lat: position.current.lat, lng: position.current.lng },
                    { lat: position.next.lat, lng: position.next.lng }
                  ]
                }
                options={{
                  strokeColor: "#041967",
                  //strokeOpacity: 0.5,
                  strokeWeight: 2,
                  geodesic: true,
                  icons: [{
                    // eslint-disable-next-line no-undef
                    icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                    offset: '50%'
                  }]
                }}
              />
            ))} */}

            {/* return an info window when click on the marker */}
            {selectedTaskFromList ? (
              <SelectedAddress
                key={selectedTaskFromList?.id}
                arrivalLong={selectedTaskFromList?.arrival_long}
                arrivalLat={selectedTaskFromList?.arrival_lat}
                recipientName={selectedTaskFromList?.recipient_name}
                destinationAddress={selectedTaskFromList?.destination_address}
                routeIndex={taskIndex}
              />
            ) : (
              <></>
            )}
          </GoogleMap>

          <RecipientList>
            {storeData.id !== 'all' ? (
              <div>
                {/* If there is any driver data */}
                {drivers !== null && drivers.length > 0 ? (
                  <div>
                    {/* If a driver on drop down is clicked, load the drive,
                    else load all driver */}
                    {selectedDriverTask ? (
                      <DriverList
                        driverInfo={selectedDriverTask}
                        currentSelectedDriver={selectedDriverFromList}
                        selectedDriver={handleDriverListData}
                        selectedTask={handleTaskListData}
                        filteredDate={filteredDate}
                        npiKey={npiKey}
                        taskIndex={handleTaskIndex}
                      />
                    ) : (
                      drivers.map((driver) => (
                        <DriverList
                          key={driver.id}
                          driverInfo={driver}
                          currentSelectedDriver={selectedDriverFromList}
                          selectedDriver={handleDriverListData}
                          selectedTask={handleTaskListData}
                          filteredDate={filteredDate}
                          npiKey={npiKey}
                          taskIndex={handleTaskIndex}
                        />
                      ))
                    )}
                  </div>
                ) : (
                  <NoDriverTitle>No drivers to display</NoDriverTitle>
                )}
              </div>
            ) : (
              <NoDriverTitle>No drivers to display</NoDriverTitle>
            )}

          </RecipientList>
        </MapBox>
      </section>
    </>
  );
};

//export default React.memo(DriverMap);
export default DriverMap;