import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import RoomIcon from '@mui/icons-material/Room';
import { ReactComponent as DriverAvatar } from "assets/images/icons/driver-avatar.svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { getAllTasks } from "apis/driverClient";

const ItemSelector = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected, // Use the theme palette or any custom color
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.action.hover, // Optionally change color on hover
    },
}));

const SubItemsBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between"
}))

const ListSubItem = styled(Typography)(() => ({
    fontSize: "10px",
    fontWeight: 400,
    maxWidth: "50%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
}));

const ListFullItem = styled(Typography)(() => ({
    fontSize: "10px",
    fontWeight: 400,
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
}));

const DriverList = ({ driverInfo, currentSelectedDriver, selectedDriver, selectedTask, filteredDate, npiKey, taskIndex }) => {
    const [tasksInfo, setTasksInfo] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    function handleDriverClick() {
        selectedDriver(driverInfo);
        setOpen(!open);
    }

    function setData(task, index) {
        selectedTask(task);
        taskIndex(index);
        setSelectedIndex(index);
    }

    useEffect(() => {
        if (currentSelectedDriver) {
            if (currentSelectedDriver.id !== driverInfo.id) {
                setOpen(false);
            }
        }
    }, [currentSelectedDriver])

    function formatDateTime(dateTimeStr) {
        const date = new Date(dateTimeStr);

        const options = {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        return date.toLocaleString('en-US', options);
    }

    async function fetchTasksData() {
        if (npiKey && filteredDate && driverInfo) {
            const response = await getAllTasks(
                driverInfo.onfleet_worker_id,
                npiKey,
                `${filteredDate} 00:00:00`,
                `${filteredDate} 23:59:59`);
            return response;
        }
    }

    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    }

    async function filterTasksData() {
        const fetchedData = await fetchTasksData();
        if (fetchedData) {
            const tasksData = fetchedData.tasks;
            let newPairs = [];
            const date = new Date();
            const usDate = convertTZ(date, "America/New_York");
            if (tasksData !== null) {
                for (let i = 0; i < tasksData.length; i++) {
                    const taskDate = new Date(tasksData[i].onfleet_time);
                    if (tasksData[i].status === 1
                        && taskDate.getFullYear() === usDate.getFullYear()
                        && taskDate.getMonth() === usDate.getMonth()
                        && taskDate.getDate() === usDate.getDate()) {
                    } else {
                        newPairs.push(tasksData[i]);
                    }
                }
                setTasksInfo(newPairs);
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            filterTasksData();
        }, 500)
    }, [driverInfo])

    return (
        <div>
            {Object.keys(driverInfo).length !== 0 ? (
                <div key={driverInfo.id}>
                    <ListItemButton onClick={() => handleDriverClick()}>
                        <ListItemIcon>
                            <DriverAvatar />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1" style={{ fontSize: "18px", fontWeight: 500 }}>
                                    {`${driverInfo.name}`}
                                </Typography>
                            }
                        />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    {tasksInfo //all on working tasks or complete task will be in the first
                        && tasksInfo.map
                        && tasksInfo.map((task, index) => (
                            <div>
                                {task.onfleet_time ? (
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ItemSelector
                                                sx={{ pl: 4 }}
                                                onClick={() => setData(task, index)}
                                                selected={selectedIndex === index}
                                            >
                                                <ListItemIcon style={{ width: "20px", height: "20px" }}>
                                                    <RoomIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    secondary={
                                                        <Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body1"
                                                                style={{ fontSize: "14px", fontWeight: 500 }}
                                                                color="text.primary"
                                                            >
                                                                {index + 1} - {task.destination_address}<br />
                                                            </Typography>
                                                            <SubItemsBox>
                                                                <ListSubItem>
                                                                    {task.recipient_name}
                                                                </ListSubItem>
                                                                <ListSubItem>
                                                                    {formatDateTime(task.onfleet_time)}
                                                                </ListSubItem>
                                                            </SubItemsBox>
                                                        </Fragment>
                                                    }
                                                />
                                            </ItemSelector>
                                        </List>
                                    </Collapse>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}

                    {tasksInfo //If onfleet time is null, then the task will be in the end of the list
                        && tasksInfo.map
                        && tasksInfo.map((task, index) => (
                            <div>
                                {!task.onfleet_time ? (
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ItemSelector
                                                sx={{ pl: 4 }}
                                                onClick={() => setData(task, index)}
                                                selected={selectedIndex === index}
                                            >
                                                <ListItemIcon style={{ width: "20px", height: "20px" }}>
                                                    <RoomIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    secondary={
                                                        <Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body1"
                                                                style={{ fontSize: "14px", fontWeight: 500 }}
                                                                color="text.primary"
                                                            >
                                                                {index + 1} - {task.destination_address}<br />
                                                            </Typography>
                                                            {task.onfleet_esttime_arrival ? (
                                                                <SubItemsBox>
                                                                    <ListSubItem>
                                                                        {task.recipient_name}
                                                                    </ListSubItem>
                                                                    <ListSubItem>
                                                                        {formatDateTime(task.onfleet_esttime_arrival)}
                                                                    </ListSubItem>
                                                                </SubItemsBox>
                                                            ) : (
                                                                <SubItemsBox>
                                                                    <ListFullItem>
                                                                        {task.recipient_name}
                                                                    </ListFullItem>
                                                                </SubItemsBox>
                                                            )}
                                                        </Fragment>
                                                    }
                                                />
                                            </ItemSelector>
                                        </List>
                                    </Collapse>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default DriverList;